/**
 * INTRO.ROUTE.TSX
 * Intro text before start the questionnaire
 */

import { useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"
import Button from "../components/button"
import Page from "../components/page"
import { Session } from "@/model/session.model"
import SaveModal from "@/components/save-modal"
import { RouteComponentProps, withRouter } from "react-router"
import i18n from "@/translate/i18n"
import { store } from "@/index"
import { editLanguage } from "@/redux/actions"
import LanguageSelector from "@/components/language-selector"

interface StateProps extends WithTranslation, RouteComponentProps {
  session: Session
}

const MODAL_START = "MODAL_START"

function IntroRoute(props: StateProps) {
  const { t } = props

  //Display dialog
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Set language
  function changeLanguage(lg) {
    store.dispatch(editLanguage(lg))
    i18n.changeLanguage(lg)
  }

  //Launch
  //Open form if needed
  function launch() {
    setCurrentModal(MODAL_START)
  }

  return (
    <Page
      backgroundColor={
        props.session.messageOptions.backgroundColor
          ? props.session.messageOptions.backgroundColor
          : props.session.colors.brandPrimary
      }
      color={
        props.session.messageOptions.textColor ? props.session.messageOptions.textColor : props.session.colors.brandText
      }
      tabTitle={props.session.surveyName}>
      {currentModal === MODAL_START && (
        <SaveModal
          action="start"
          onClose={() => setCurrentModal(null)}
          onSaved={() => props.history.push("/questions")}
        />
      )}

      <div
        className={"intro-text" + (props.session.messageOptions.isAlternativeLayout ? " intro-text-alternative" : "")}>
        <div className="flex">
          <div className="flex1">
            <LanguageSelector
              language={props.session.language}
              languages={props.session.languages}
              onChange={(lang: string) => changeLanguage(lang)}
            />

            <h2>{props.session.getMessageOptions(props.session.language, "title")}</h2>

            {props.session.messageOptions.isWithTitleSecondary && (
              <h3>{props.session.getMessageOptions(props.session.language, "titleSecondary")}</h3>
            )}
          </div>

          {props.session.messageOptions.isAlternativeLayout && (
            <div className="intro-image intro-image-alternative">
              <img src={props.session.imageUrl} alt="intro" />
            </div>
          )}
        </div>

        <p style={{ whiteSpace: "pre-line" }}>{props.session.getMessageOptions(props.session.language, "content")}</p>

        <div style={{ height: "12px" }}></div>

        <Button
          backgroundColor={
            props.session.messageOptions.buttonColor
              ? props.session.messageOptions.buttonColor
              : props.session.colors.cta
          }
          className={props.session.isPreview ? "secondary" : "primary"}
          color={props.session.isPreview ? undefined : "white"}
          isLarge
          onClick={props.session.isPreview ? () => alert(t("is_preview")) : launch}>
          {t("launch")}
        </Button>

        <Button isLarge color="white" onClick={() => props.history.push("/faq")}>
          {t("faq_button")}
        </Button>
      </div>

      {!props.session.messageOptions.isAlternativeLayout && (
        <div className="intro-image">
          <img src={props.session.imageUrl} alt="intro" />
        </div>
      )}
    </Page>
  )
}

const mapStateToProps = (state) => ({
  session: state.reducers
})

export default withRouter(connect(mapStateToProps)(withTranslation()(IntroRoute)))
